import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Container from '@components/Container'
import Hero from '@components/Hero'
import Footer from '@components/Footer'
import PageComponents from '@components/PageComponents'
import SEO from '@components/partials/SEO'
import { metaData } from '@fragments/meta'

export const PageTemplate = ({ hero, components, page }) => {
  return (
    <Container className={page.slug} page={page}>
      <Hero {...hero} {...page} />
      <main>
        <article>
          <PageComponents components={components} page={page} />
        </article>
      </main>
      <Footer />
    </Container>
  )
}

PageTemplate.propTypes = {
  title: PropTypes.string,
  page: PropTypes.object,
  hero: PropTypes.object,
  components: PropTypes.array,
}

const Page = ({ data }) => {
  const {
    wordpressPage: { yoast_meta: meta, ...page },
  } = data

  page.acf.hero.heading = page.acf.hero.heading || page.title
  page.acf.hero.type = page.designations.includes('front') ? 'full' : 'basic'

  return (
    <>
      <SEO {...meta} path={page.path} />
      <PageTemplate
        hero={page.acf.hero}
        components={page.acf.content_page}
        page={page}
      />
    </>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query PageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      slug
      path
      designations
      acf {
        hero {
          ...HeroFragment
        }
        content_page {
          __typename
          ...AccordionBlockComponentFragment
          ...BannerComponentFragment
          ...CardsComponentFragment
          ...FeaturedProjectsComponentFragment
          ...FormComponentFragment
          ...ImageComponentFragment
          ...ImageBlocksComponentFragment
          ...LogoGridComponentFragment
          ...ListBlockComponentFragment
          ...SliderComponentFragment
          ...TeamComponentFragment
          ...TestimonialsComponentFragment
          ...TextComponentFragment
          ...TextWithImagesFragment
          ...TilesComponentFragment
        }
      }
      yoast_meta {
        ...metaData
      }
    }
  }
`

export default Page
